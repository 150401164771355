
::v-deep td {
    &.text-truncate {
        max-width: 1px;
    }
    &.white-space-pre-line {
        white-space: pre-line;
    }
    &.line-height-125 {
        line-height: 1.25;
    }
}
